export class ClientService {
  constructor(accessToken) {
    this.API_URL = process.env.REACT_APP_API_URL;
    this.accessToken = accessToken;
  }

  async getSpread(clientname) {
    const the_url = this.API_URL + "/spread?clientname=" + clientname;
    const response = await fetch(the_url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    // console.log('ClientService.getSpread() response', response.json());
    return await response.json();
  }

  async getClienTradingVolume(clientId, assetPair, duration) {
    const response = await fetch(this.API_URL + "/clientradingvolume", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({
        clientId: clientId,
        assetPair: assetPair,
        duration: duration,
      }),
    });
    return await response.json();
  }
  // get Client
  async getClient(id) {
    const response = await fetch(this.API_URL + "/client/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    return await response.json();
  }

  /**
   * Get Fireblocks for Client by Copper ID
   */
  async getFireblocksForClient(id) {
    const response = await fetch(this.API_URL + "/fireblocks/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    return await response.json();
  }

  /**
   * Get transactions of Fireblocks, or Bank
   * @param {*} type 'fireblocks', 'bcb', 'primetrust', etc.
   * @param {*} id client copper ID
   * @returns Transaction objects of specified type
   */
  async getTransactionsForClient(type, id) {
    const response = await fetch(`${this.API_URL}/${type}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    return await response.json();
  }

  async getAllClients() {
    // console.log('ClientService.getAllClients() accessToken', this.accessToken);
    const response = await fetch(
      this.API_URL +
        `/clients`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }
    );
    return await response.json();
  }

  async getAllSourceWallets() {
    const response = await fetch(this.API_URL + "/fbsendingwallet", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    return await response.json();
  }

  async getAllDestinationWallets() {
    const response = await fetch(this.API_URL + "/fbreceivingwallet", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    return await response.json();
  }

  async deleteClientFBWallet(walletId) {
    const response = await fetch(`${this.API_URL}/fbwallets/${walletId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });

    return await response.json();
  }

  async createClient(data) {
    const response = await fetch(this.API_URL + "/client", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ task: data }),
    });
    return await response.json();
  }

  async deleteClient(clientId) {
    const response = await fetch(this.API_URL + `/client/${clientId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    return await response.json();
  }

  async editClient(data) {
    const response = await fetch(this.API_URL + "/client", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ task: data }),
    });
    return await response.json();
  }

  async editClientFee(data) {
    const response = await fetch(this.API_URL + "/clientfee", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ task: data }),
    });
    return await response.json();
  }

  async editClientCoryCommission(data) {
    const response = await fetch(this.API_URL + "/coryCommission", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ task: data }),
    });
    return await response.json();
  }

  async updateClientRateHikeOption(clientId, isEnabled) {
    const response = await fetch(this.API_URL + "/clientratehike", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ clientId: clientId, enableRateHike: isEnabled }),
    });
    return await response.json();
  }

  async addWalletTask(data) {
    // console.log('addWalletTask()', data);
    const response = await fetch(this.API_URL + "/wallet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ wallet: data }),
    });
    return await response.json();
  }

  async deleteWalletTask(walletId) {
    const response = await fetch(`${this.API_URL}/wallet/${walletId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });

    return await response.json();
  }

  /**
   * Delete Clientname
   * @param data is the Clientname id
   */
  async deleteClientnameTask(data) {
    // console.log('deleteClientnameTask()', data);
    const response = await fetch(this.API_URL + "/clientname/" + data, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    return await response.json();
  }

  async addClientnameTask(data) {
    // console.log('addClientnameTask()', data);
    const response = await fetch(this.API_URL + "/clientname", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ clientname: data }),
    });
    return await response.json();
  }

  async updateClientEnity(clientId, entity) {
    // console.log('updateClientEnity()', clientId, entity);
    const response = await fetch(this.API_URL + "/cliententity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ clientId: clientId, entity: entity }),
    });
    return await response.json();
  }

  async updateClientBanks(clientId, updatedClientBanks, removingClientBanks) {
    // console.log('updateClientBanks()', clientId);
    let formatedUpdate = updatedClientBanks.map((clientBank) => ({
      clientId: clientId,
      bank: clientBank,
    }));
    const response = await fetch(this.API_URL + "/clientbanks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({
        removedBanks: removingClientBanks,
        clientBanks: formatedUpdate,
      }),
    });
    return await response.json();
  }

  async updateFBWalletClientRelationship(clientId, walletId) {
    // console.log('updateFBWalletClientRelationship()', clientId);
    const response = await fetch(this.API_URL + "/updatefbwallet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ clientId: clientId, walletId: walletId }),
    });
    return await response.json();
  }

  async getClientReportBlob(clientCopperID, fileType) {
    const contentTypes = {
      csv: "text/csv",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      pdf: "application/pdf",
    };

    const response = await fetch(
      `${this.API_URL}/client/${clientCopperID}/report?fileType=${fileType}`,
      {
        headers: {
          "Content-Type": contentTypes[fileType] ?? "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }
    );

    return await response.blob();
  }

  async getClientBalanceListBlob(fileType, cacheDate) {
    const contentTypes = {
      csv: "text/csv",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };

    if (!(fileType in contentTypes)) {
      console.error(
        `ERROR: ${fileType} is not a valid file extension for balance export`
      );
      return null;
    }

    const response = await fetch(
      `${this.API_URL}/clients/balance_export?fileType=${fileType}${cacheDate ? `&cacheDate=${cacheDate.toISOString()}` : ''}`,
      {
        headers: {
          "Content-Type": contentTypes[fileType],
          Authorization: `Bearer ${this.accessToken}`,
        },
      }
    );

    return await response.blob();
  }

  async getClientReportData(clientCopperID) {
    const response = await fetch(
      `${this.API_URL}/client/${clientCopperID}/report`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }
    );

    return response.json();
  }

  async getClientBalanceData(clientCopperID, abortController) {
    try {
      const response = await fetch(
        `${this.API_URL}/client/${clientCopperID}/balances`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          signal: abortController.signal,
        }
      );

      return response.json();
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Aborted client balance GET request");
      } else {
        console.error(err);
      }

      return null;
    }
  }

  async getClientBalanceOffsets(clientCopperId) {
    const response = await fetch(
      `${this.API_URL}/clients/${clientCopperId}/balance_offsets`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }
    );

    return await response.json();
  }

  async createClientBalanceOffset(clientCopperId, balanceOffset) {
    const response = await fetch(
      `${this.API_URL}/clients/${clientCopperId}/balance_offsets`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: JSON.stringify(balanceOffset),
      }
    );

    return await response.json();
  }

  async updateClientBalanceOffset(clientCopperId, balanceOffset) {
    const response = await fetch(
      `${this.API_URL}/clients/${clientCopperId}/balance_offsets`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: JSON.stringify(balanceOffset),
      }
    );

    return await response.json();
  }

  async deleteClientBalanceOffset(balanceOffsetId) {
    const response = await fetch(
      `${this.API_URL}/clients/balance_offsets/${balanceOffsetId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      }
    );

    return await response.json();
  }

  async createNewClientCommissionThreshold(
    clientCopperID,
    threshold,
    rate,
    interval,
    clientName,
    clientPrevRate,
    assetpair
  ) {
    const response = await fetch(`${this.API_URL}/addclientcommission`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({
        data: {
          clientId: clientCopperID,
          threshold: threshold,
          rate: rate,
          duration: interval,
          clientName: clientName,
          clientPrevRate: clientPrevRate,
          assetpair: assetpair,
        },
      }),
    });

    return response.json();
  }

  async getClientCommissionThresholdByClientId(clientCopperID, tradingPair) {
    const response = await fetch(`${this.API_URL}/clientcommission`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({
        clientId: clientCopperID,
        assetPair: tradingPair,
      }),
    });

    return response.json();
  }

  async updateClientCommissionThresholdById(
    commissionStructure,
    clientName,
    clientPrevRate
  ) {
    const response = await fetch(`${this.API_URL}/updateclientcommission`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({
        data: commissionStructure,
        clientName: clientName,
        clientPrevRate: clientPrevRate,
      }),
    });

    return response.json();
  }

  async deleteClientCommissionThresholdById(id) {
    const response = await fetch(`${this.API_URL}/deleteclientcommission`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ id: id }),
    });

    return response.json();
  }



  async getAdamDeltec(pageSize, pageNum) {
    const response = await fetch(`${this.API_URL}/deltec?size=${pageSize}&page=${pageNum}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", 
            Authorization: `Bearer ${this.accessToken}`,
        },
    });

    return await response.json();
}


  async setDeltecClient(ledgerId, clientCopperID) {
    const response = await fetch(this.API_URL + "/adam-deltec/setclient", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      body: JSON.stringify({ ledgerId, clientCopperID }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Network response was not ok");
    }

    return response.json();
  }

  async getAdamClientEmails(clientCopperID) {
    const response = await fetch(`${this.API_URL}/clientEmails/${clientCopperID}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.accessToken}`
        }
    });

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Failed to fetch client emails. Status: ${response.status}`);
    }
}


async addAdamClientEmails(clientCopperID, email ) {
    const response = await fetch(`${this.API_URL}/clientEmails/${clientCopperID}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.accessToken}`
        },
        body: JSON.stringify(email)
    });

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Failed to fetch client emails. Status: ${response.status}`);
    }
}

async deleteAdamClientEmail(clientCopperID, email) {
    const response = await fetch(`${this.API_URL}/clientEmails/${clientCopperID}/${email}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${this.accessToken}`
        }
    });

    // Check if the response is ok
    if (!response.ok) {
        throw new Error(`Failed to delete client email. Status: ${response.status}`);
    }

    // Check the content type of the response
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
        // Handle JSON response
        return await response.json();
    } else {
        // Handle non-JSON response (e.g., plain text)
        const textResponse = await response.text();
        return textResponse;
    }
}

async addNotificationTypesToEmail(emailId, notificationType) {
  const url = `${this.API_URL}/notificationTypes/${emailId}`;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.accessToken}`
    },
    body: JSON.stringify({ notificationType }) // Changed key to notificationType
  }).then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  });
}

async getNotificationTypesForEmail(emailId) {
  const url = `${this.API_URL}/notificationTypes/${emailId}`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.accessToken}`
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data; // This should contain the notification types for the email
  } catch (error) {
    console.error('Error fetching notification types:', error);
    // Handle the error accordingly
    throw error;
  }
}



async removeNotificationTypesFromEmail(emailId, notificationTypes) {
    const url = `${this.API_URL}/notificationTypes/${emailId}`;
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.accessToken}`
            },
            body: JSON.stringify({ notificationTypes }) // Send the array of notification types
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // The removal was successful, you can return a success message or handle it as needed.
        return 'Notification types removed successfully';
    } catch (error) {
        console.error('Error removing notification types:', error);
        // Handle the error accordingly
        throw error;
    }
}

async getAdamDeletedTrades(TradeID) {
  const response = await fetch(`${this.API_URL}/adamDeletedTrade/${TradeID}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.accessToken}`
      }
  });

  if (response.ok) {
      return await response.json();
  } else {
      // Updated the error message to reflect the actual operation being performed.
      throw new Error(`Failed to fetch deleted trade. Status: ${response.status}`);
  }
}



}