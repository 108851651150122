import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import copy from 'copy-to-clipboard';
import { Stack } from '@mui/material';
import { rates } from './rates';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './TradePreview.css'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const mobileStyle = {
    position: 'absolute',
    top: '46%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function TradePreviewDialog({open, submitTicket, handleClose, askingTermsSelect, clientName, tradeTicketID, tradingPair, date, action, spread, amount, spotRate,clientDealtRate, amountToTrade, decimalPlace, dealtDecimal, loading, liquidityProvider, execAmt, execPrice, platform, orderId, commission, isCommissionEnabled, notPushToExecChat, handleNotPushExecChange, email  }) {
    const [isCopied, setIsCopied] = React.useState(false);
    const [isIndicativeCopied, setIsIndicativeCopied] =  React.useState(false);
    const [isTradeSummaryCopied, setIsTradeSummaryCopied] = React.useState(false);
    const [decimal, setDecimal] = React.useState(2);
    const [decimalDealt, setDecimalDealt] = React.useState(4);

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));
    /**
     * React useEffect hook that updates the decimal values for the trading pairs
     * whenever the tradingPair state changes.
     */
    React.useEffect(() => {
        const [tradingPair1, tradingPair2] = tradingPair.split('/');
        if(tradingPair1 && tradingPair2){
            if(rates[tradingPair1]){
                let rate = rates[tradingPair1] ? rates[tradingPair1].decimal : 8
                setDecimal(rate)
            }
            if(rates[tradingPair2]){
                let rate = rates[tradingPair2] ? rates[tradingPair2].decimal : 8
                setDecimalDealt(rate)
            }
        }
    },[tradingPair])
    // console.log( askingTermsSelect, action, amount, amountToTrade, tradingPair);
    // console.log(tradingPair && tradingPair.split('/')[1], rates[tradingPair.split('/')[1]].decimal)
    // console.log(parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: rates[tradingPair.split('/')[1]].decimal, minimumFractionDigits: rates[tradingPair.split('/')[1]].decimal}));
    // console.log(tradingPair && tradingPair.split('/')[0], rates[tradingPair.split('/')[0]])
    // console.log('decimalPlace', decimalPlace)
    // console.log('dealtDecimal', dealtDecimal);
    const handleCopyTradeSummaryTicket = () => {
        setIsTradeSummaryCopied(false)
        setIsCopied(false)
        setIsIndicativeCopied(false)
        let ticket = `*Trade Summary* \n`+
            `Client: ${clientName} \n` +
            `Date: ${date} \n` +
            `CCY Deposited: ${
                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]} \n` +
            `Spot Price: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `Dealt Rate: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `CCY Purchased: ${
                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
            }`
        copy(ticket)
        setIsTradeSummaryCopied(true)
    }
    const handleCopyIndicativeTicket = () => {
        setIsIndicativeCopied(false)
        setIsTradeSummaryCopied(false)
        setIsCopied(false)
        let ticket = `*Indicative Summary* \n`+
            `Client: ${clientName} \n` +
            `Date: ${date} \n` +
            `CCY Deposited: ${
                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]} \n` +
            `Spot Price: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `Dealt Rate: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `CCY Purchased: ${
                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
            }`
        copy(ticket)
        setIsIndicativeCopied(true)
    }
    /**
     * Handles copying the trade ticket information to the clipboard.
     */
    const handleCopyTradeTicket = () => {
        setIsIndicativeCopied(false)
        setIsTradeSummaryCopied(false)
        setIsCopied(false)
        let ticket = isCommissionEnabled? `*Trade Summary* \n`+
            `Client: ${clientName} \n` +
            `Date: ${date} \n` +
            `CCY Deposited: ${
                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]} \n` +
            `Spot Price: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `Dealt Rate: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `CCY Purchased: ${
                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
            } \n` +
            `\n` +
            `ID: ${tradeTicketID}\n` +
            `\n` +
            `*Exec Ticket*\n` +
            `Date: ${date}\n` +
            `LP: ${liquidityProvider}\n` +
            `Amt: ${tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]}\n` +
            `Price: ${execPrice + ' ' + tradingPair}\n` +
            `Platform: ${platform}\n` +
            `Order ID: ${orderId}\n` 
            + `\n` +
            `Comms: ${askingTermsSelect == 'Fiat'? 
                tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                    parseFloat(commission).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})
                    :parseFloat(commission).toLocaleString(undefined, {maximumFractionDigits: 8, minimumFractionDigits: 6})
                :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                    parseFloat(commission).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) 
                    : parseFloat(commission).toLocaleString(undefined, {maximumFractionDigits:8, minimumFractionDigits: 6})} \n`
        :`*Trade Summary* \n`+
            `Client: ${clientName} \n` +
            `Date: ${date} \n` +
            `CCY Deposited: ${
                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                            parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                            :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                        :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                            parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                            : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]} \n` +
            `Spot Price: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `Dealt Rate: ${
                askingTermsSelect == 'Fiat'? 
                    parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                    : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair} \n` +
            `CCY Purchased: ${
                askingTermsSelect == 'Fiat'? 
                                    action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                    : action == 'Buy'? 
                                        tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                        : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1] : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
            } \n` +
            `\n` +
            `ID: ${tradeTicketID}\n` +
            `\n` +
            `*Exec Ticket*\n` +
            `Date: ${date}\n` +
            `LP: ${liquidityProvider}\n` +
            `Amt: ${tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]}\n` +
            `Price: ${execPrice + ' ' + tradingPair}\n` +
            `Platform: ${platform}\n` +
            `Order ID: ${orderId}\n` 
        copy(ticket)
        setIsCopied(true)
    }
    
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={isXs? mobileStyle :style}>
                    <div 
                        style={{
                            display: 'flex',
                            flexDirection: isXs ? 'column' : 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <div style={{
                            marginBottom: isXs ? 5 : 0,
                            // marginTop: isXs ? 10:0
                        }}>
                            <Stack spacing={1}>
                                {isIndicativeCopied?
                                <Button color='success' onClick={() => handleCopyIndicativeTicket()}>Copied!</Button>
                                :<Button color='warning' onClick={() => handleCopyIndicativeTicket()}>Copy Indicative Ticket</Button>}
                            </Stack>
                            <Stack>
                                <Typography id="modal-modal-description" sx={{ mt: 1, alignSelf: 'center' }}>
                                    <p style={{margin: 0}}>*Indicative Summary*</p>
                                    <p style={{margin: 0}}>Client: {clientName}</p>
                                    <p style={{margin: 0}}>Date: {date}</p>
                                    <p style={{margin: 0}}>CCY Deposited: {
                                        askingTermsSelect == 'Fiat'? 
                                            action == 'Buy'? 
                                                tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                                    parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                                    :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                                :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                                    parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                                    : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                            : action == 'Buy'? 
                                                tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                                    parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                                    :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                                :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                                    parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                                    : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    }</p>
                                    <p style={{margin: 0}}>Spot Price: {
                                        askingTermsSelect == 'Fiat'? 
                                            parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                            :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                                        }
                                    </p>
                                    <p style={{margin: 0}}>Dealt Rate: {
                                        askingTermsSelect == 'Fiat'? 
                                            parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                            : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                                    }</p>
                                    <p style={{margin: 0}}>CCY Purchased: {
                                        askingTermsSelect == 'Fiat'? 
                                            action == 'Buy'? 
                                                tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                                : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                            : action == 'Buy'? 
                                                tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                                : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]}
                                    </p>
                                    {/* <p style={{marginTop: 0}}></p>
                                    <p style={{marginTop: 0}}>ID: {tradeTicketID}</p> */}
                                </Typography>
                            </Stack>
                        </div>
                        <div style={{
                            marginBottom: isXs ? 5 : 0,
                            // marginTop: isXs ? 10:0
                        }}>
                            <Stack spacing={1}>
                                {isTradeSummaryCopied?
                                <Button color='success' onClick={() => handleCopyTradeSummaryTicket()}>Copied!</Button>
                                :<Button color='secondary' onClick={() => handleCopyTradeSummaryTicket()}>Copy Trade Summary Only</Button>}
                            </Stack>
                            <Stack sx={{display: {
                                xs: 'none', 
                                sm: 'contents'
                            }}}>
                                <Typography id="modal-modal-description" sx={{ mt: 1, alignSelf: 'center' }}>
                                    <p style={{margin: 0}}>*Trade Summary*</p>
                                    <p style={{margin: 0}}>Client: {clientName}</p>
                                    <p style={{margin: 0}}>Date: {date}</p>
                                    <p style={{margin: 0}}>CCY Deposited: {
                                        askingTermsSelect == 'Fiat'? 
                                            action == 'Buy'? 
                                                tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                                    parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                                    :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                                :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                                    parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                                    : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                            : action == 'Buy'? 
                                                tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                                    parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                                    :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                                :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                                    parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                                    : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    }</p>
                                    <p style={{margin: 0}}>Spot Price: {
                                        askingTermsSelect == 'Fiat'? 
                                            parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                            :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                                        }
                                    </p>
                                    <p style={{margin: 0}}>Dealt Rate: {
                                        askingTermsSelect == 'Fiat'? 
                                            parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                            : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                                    }</p>
                                    <p style={{margin: 0}}>CCY Purchased: {
                                        askingTermsSelect == 'Fiat'? 
                                            action == 'Buy'? 
                                                tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                                : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                            : action == 'Buy'? 
                                                tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                                : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]}
                                    </p>
                                    {/* <p style={{marginTop: 0}}></p>
                                    <p style={{marginTop: 0}}>ID: {tradeTicketID}</p> */}
                                </Typography>
                            </Stack>
                        </div>
                        <div style={{
                            marginTop: isXs ? 5 : 0,
                            marginBottom: 5
                        }}>
                            <Stack spacing={1}>
                                {isCopied?
                                <Button color='success' onClick={() => handleCopyTradeTicket()}>Copied!</Button>
                                :<Button color='primary' onClick={() => handleCopyTradeTicket()}>Copy Trade Ticket</Button>}
                            </Stack>
                            <Stack>
                                <Typography id="modal-modal-description" sx={{ mt: 1, alignSelf: 'center' }}>
                                    <p style={{margin: 0}}>*Trade Summary*</p>
                                    <p style={{margin: 0}}>Client: {clientName}</p>
                                    <p style={{margin: 0}}>Date: {date}</p>
                                    <p style={{margin: 0}}>CCY Deposited: {
                                        askingTermsSelect == 'Fiat'? 
                                            action == 'Buy'? 
                                                tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                                    parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                                    :parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                                :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                                    parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                                    : parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                            : action == 'Buy'? 
                                                tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?
                                                    parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]
                                                    :parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                                :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                                    parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]
                                                    : parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits:decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                    }</p>
                                    <p style={{margin: 0}}>Spot Price: {
                                        askingTermsSelect == 'Fiat'? 
                                            parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                            :parseFloat(spotRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                                        }
                                    </p>
                                    <p style={{margin: 0}}>Dealt Rate: {
                                        askingTermsSelect == 'Fiat'? 
                                            parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: dealtDecimal, maximumFractionDigits: dealtDecimal}) + ' '+ tradingPair
                                            : parseFloat(clientDealtRate).toLocaleString(undefined, {minimumFractionDigits: decimalPlace, maximumFractionDigits: decimalPlace}) + ' '+ tradingPair
                                    }</p>
                                    <p style={{margin: 0}}>CCY Purchased: {
                                        askingTermsSelect == 'Fiat'? 
                                            action == 'Buy'? 
                                                tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                                : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ?  parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]
                                            : action == 'Buy'? 
                                                tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0]:parseFloat(amount).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]
                                                : tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[1]:parseFloat(amountToTrade).toLocaleString(undefined, {maximumFractionDigits: decimalDealt, minimumFractionDigits: decimalDealt}) + ' ' + tradingPair.split('/')[1]}
                                    </p>
                                    <p style={{marginTop: 0}}></p>
                                    <p style={{marginTop: 0}}>ID: {tradeTicketID}</p>
                                    <p style={{marginTop: 0}}></p>
                                    <p style={{margin: 0}}>*Exec Ticket*</p>
                                    <p style={{margin: 0}}>Date: {date}</p>
                                    <p style={{margin: 0}}>LP: {liquidityProvider}</p>
                                    <p style={{margin: 0}}>Amt: {tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) + ' ' + tradingPair.split('/')[0] : parseFloat(execAmt).toLocaleString(undefined, {maximumFractionDigits: decimal, minimumFractionDigits: decimal}) + ' ' + tradingPair.split('/')[0]}</p>
                                    <p style={{margin: 0}}>Price: {execPrice + ' ' + tradingPair}</p>
                                    <p style={{margin: 0}}>Platform: {platform}</p>
                                    <p style={{margin: 0}}>Order ID: {orderId}</p>
                                    <p style={{marginTop: 0}}></p>
                                    {isCommissionEnabled? <p>Comms: {askingTermsSelect == 'Fiat'? 
                                        // action == 'Buy'? 
                                            tradingPair.split('/')[1] == 'USDT' || tradingPair.split('/')[1] == 'USDC' ? 
                                                parseFloat(commission).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})
                                                :parseFloat(commission).toLocaleString(undefined, {maximumFractionDigits: 8, minimumFractionDigits: 6})
                                            :tradingPair.split('/')[0] == 'USDT' || tradingPair.split('/')[0] == 'USDC' ? 
                                                parseFloat(commission).toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2}) 
                                                : parseFloat(commission).toLocaleString(undefined, {maximumFractionDigits:8, minimumFractionDigits: 6}) 
                                        }</p> : null}
                                </Typography>
                            </Stack>
                        </div>
                    </div>
                    
                    
                    <Stack spacing={1}>
                        {/* <Stack sx={{justifyContent: 'center'}}>
                            <FormControlLabel control={<Checkbox checked={notPushToExecChat} onChange={handleNotPushExecChange}/>} label="Don't push trade to Exec"/>
                        </Stack> */}
                        {loading? 
                        <LoadingButton loading variant="outlined">
                            Submit
                        </LoadingButton>
                        :<Button variant='contained' color='primary' onClick={() => {submitTicket(); setIsCopied(false); setIsIndicativeCopied(false)}}>Submit</Button>}
                        {/* <Button variant='contained' color='secondary' onClick={() => {submitTicket(); setIsCopied(false); setIsIndicativeCopied(false)}}>Submit to Atlas</Button> */}
                        <Button variant='contained' color='error' onClick={() => {handleClose(); setIsCopied(false); setIsIndicativeCopied(false)}}>Cancel</Button>
                    </Stack>
                    
                </Box>
            </Modal>
        </div>
    );
}

export default TradePreviewDialog;